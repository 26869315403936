import React, {useState, useMemo, useContext} from 'react';
// import assetsList from '../data/nft';
import artworks1 from '../data/artworks1.json';
import artworks2 from '../data/artworks2.json';
import artworks from '../data/artworks.json';
import AssetComponent from '../components/Asset';
import PaginationComponent from '../components/Pagination';
import SerachComponent from '../components/Serach';
import {SerachStateContext} from '../contexts/serach';
import usePrevious from '../hook/usePrevious';
import useI18n from '../hook/useI18n';
import Modal from "../components/Modal";
import CertificationModal from "../components/CertificationModal";
import SellModal from "../components/SellModal";
import TipModal from "../components/TipModal";
import {ModalStateContext} from "../contexts/modal";

let PageSize = 20;
const Assets = () => {

    // let assetsList = [...artworks1, ...artworks2];
    let assetsList = artworks;

    const {t} = useI18n();
    const [show, setShow] = useState(false);
    const [showSellModal, setShowSellModal] = useState(false);
    const [showTipModal, setShowTipModal] = useState(false);
    const [data, setData] = useState(null);
    let [currentPage, setCurrentPage] = useState(1);
    let [status, setStatus] = useState('');
    const {searchKeyword} = useContext(SerachStateContext);
    const {isModalOpen} = useContext(ModalStateContext);
    const prevCondition = usePrevious({searchKeyword, status});
    const assetsListData = assetsList && assetsList.filter((asset) => {

        if (!searchKeyword) {
            if (status) {
                return (
                    asset.status === parseInt(status)
                )
            }
            return (
                !searchKeyword
            )
        }

        if (searchKeyword) {

            return (
                asset.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
                asset.name_en.toLowerCase().includes(searchKeyword.toLowerCase()) ||
                asset.creator.toLowerCase().includes(searchKeyword.toLowerCase()) ||
                asset.creator_en.toLowerCase().includes(searchKeyword.toLowerCase()) ||
                asset.certi_no.toLowerCase().includes(searchKeyword.toLowerCase())

            )
        }
    });

    const currentAssetsData = useMemo(() => {

        if (prevCondition) {

            if (prevCondition.searchKeyword !== searchKeyword || prevCondition.status !== status) {
                setCurrentPage(1);
            }
        }

        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;


        return assetsListData.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, searchKeyword, status]);

    const handleOptionChange = (event) => {
        setStatus(event.target.value)
    };

    const handleSellModal = (data)=>{
        setShowSellModal(!showSellModal);
        setData(data)
    };

    const handleTipModal = ()=>{
        setShowTipModal(!showTipModal)
    }

    const handlecloseSellModal = ()=>{
        setShowSellModal(false)
    };

    const handlecloseTipModal = ()=>{
        setShowTipModal(false);
    }

    const handleModal = (data) => {
        setShow(!show);
        setData(data)
    };

    const handlecloseModal = () => {
        setShow(false)
    };
    return (
        <div>
            <div className="banner banner3">
                <Modal show={isModalOpen}/>
                <CertificationModal show={show} data={data} handleClose={handlecloseModal}/>
                <TipModal show={showTipModal} handleClose={handlecloseTipModal}/>
                <SellModal show={showSellModal} data={data} handleClose={handlecloseSellModal}/>
                <div className="title">
                    <h3>{t('metaassets.home.bottom')}</h3>
                    <a href="mailto:sung@vmage.com.tw?subject=我想要了解如何認證">
                        <button className="btn_fill">&#x2611;YES，{t('metaassets.home.bottomOfLink')}</button>
                    </a>
                </div>
            </div>
            <main>
                <section id="assets">
                    <h2>{t('metaassets.asset.title')}</h2>
                </section>
                <section className="desc">
                    <h3>{t('metaassets.asset.term')}</h3>
                    <p>{t('metaassets.asset.termOfContent')}</p>
                </section>
                <SerachComponent/>
                <section className="assets_table">
                    <p className="instruction">
                        <span className="arrow">←</span><span className="arrow">→</span>
                        <span className="desc">左右滑動以顯示更多資料</span></p>
                    <table>
                        <thead>
                        <tr>
                            <th className="no headcol">#</th>
                            <th className="item_img headcol2">{t('metaassets.asset.nft')}</th>
                            <th>{t('metaassets.asset.exchange')}</th>
                            <th>{t('metaassets.asset.name')}</th>
                            <th><select id="invest" onChange={handleOptionChange} defaultValue={''}>
                                <option value="">{t('metaassets.asset.investment')}</option>
                                <option value="1">{t('metaassets.asset.released')}</option>
                                <option value="0">{t('metaassets.asset.unreleased')}</option>
                            </select></th>
                            <th>{t('metaassets.asset.qty')}</th>
                            <th>{t('metaassets.asset.creator')}</th>
                            {/*<th>{t('metaassets.asset.price')}</th>*/}
                            <th>{t('metaassets.asset.category')}</th>
                            {/*<th>{t('metaassets.asset.entity')}</th>*/}
                            <th>{t('metaassets.asset.size')}</th>
                            <th className="agent">{t('metaassets.asset.release')}</th>
                            <th>{t('metaassets.asset.authorize')}</th>
                            <th>{t('metaassets.asset.certi_no')}</th>
                            <th>{t('metaassets.asset.certi_date')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            currentAssetsData.length > 0 ?
                                currentAssetsData.map((data, key) => {
                                    return <AssetComponent key={data.id} data={data} id={key}
                                                           PageSize={PageSize}
                                                           currentPage={currentPage}
                                                           handleTipModal = {handleTipModal}
                                                           handleModal={handleModal}
                                                           handleSellModal={handleSellModal}
                                    />
                                }) :
                                <tr className="no_result">
                                    <td colSpan="11"><p
                                        className="alert">{t('metaassets.asset.serachErr')} {searchKeyword}</p></td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </section>

                <section className="table_pagination">
                    <PaginationComponent
                        className="pagination"
                        currentPage={currentPage}
                        totalCount={assetsListData.length}
                        pageSize={PageSize}
                        onPageChange={page => {
                            setCurrentPage(page)
                        }}
                    />
                </section>
                <p className="desc"><span className="alert">&#x26A0;</span>
                    {t('metaassets.asset.Important')}
                </p>
            </main>
        </div>
    )
};

export default Assets;